import React, {useState, useEffect} from "react";
import { Box, Typography, Grid, Card, CardContent, Button, List, ListItemIcon, ListItemText, Dialog, DialogContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../../../config/config";
import img1 from "../MaratonCalleAngosta2025/banner.jpg";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';
import deslinde from '../MaratonCalleAngosta2025/DESLINDE.pdf';
import info from '../MaratonCalleAngosta2025/info.jpeg';
import R21k from '../MaratonCalleAngosta2025/21k.jpeg';
import R10k from '../MaratonCalleAngosta2025/10k.jpeg';
import R5k from '../MaratonCalleAngosta2025/5k.jpeg';
import R3k from '../MaratonCalleAngosta2025/3k.jpeg';
import agua from '../MaratonCalleAngosta2025/agua.jpeg';

const CalleAngosta2025 = () => {
  const navigate = useNavigate();
  const [periods, setPeriods] = useState([]);
  const isSoldOut = false;
  const [openModal, setOpenModal] = useState(false); // Controla si el modal está abierto
  const [currentImage, setCurrentImage] = useState(null); // Imagen actual en el modal

  // Obtener los periodos de inscripción desde el backend
  useEffect(() => {
    const fetchPeriods = async () => {
      try {
        const response = await axios.get(`${config.INSCRIPTION_URL}/api/inscription-periods`, {
          params: { raceName: "maraton_calle_angosta_2025" }, // Filtra por nombre de la carrera
        });
        // Filtrar los periodos que son válidos para la fecha actual
        const currentDate = new Date();
        const filteredPeriods = response.data.periods.filter((period) => {
          const startDate = new Date(period.startDate); // Fecha de inicio del periodo
          const endDate = new Date(period.endDate); // Fecha de finalización del periodo
          return currentDate >= startDate && currentDate <= endDate; // Periodos activos
        });

        setPeriods(filteredPeriods);
      } catch (error) {
        console.error("Error al obtener los períodos:", error);
      }
    };

    fetchPeriods();
  }, []);

  const handleInscribirse = (raceName, distance, periodoInscripcion, price, image) => {
    const numericPrice = parseFloat(price);
    const priceTax = 0 // El organizador se hace cargo de la comision TENGO QUE VER ESTO
    // Redirige al componente Forms con los datos seleccionados
    navigate(`/${raceName}/forms03`, {
      state: { distance, periodoInscripcion, price: numericPrice, priceTax: priceTax.toFixed(2), image },
    });
  };

  const handleOpenModal = (image) => {
    setCurrentImage(image); // Establece la imagen actual
    setOpenModal(true); // Abre el modal
  };

  const handleCloseModal = () => {
    setOpenModal(false); // Cierra el modal
    setCurrentImage(null); // Limpia la imagen actual
  };

  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        padding: 4,
        borderRadius: 2,
        maxWidth: "90%",
        margin: "auto",
        marginTop: 4,
        marginBottom: 4,
        fontFamily: "Arial, sans-serif",
        boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
      }}
    >
      {/* Banner Image */}
      <Box
        component="img"
        src={img1}
        alt="Banner"
        sx={{
          width: "100%",
          borderRadius: 2,
          marginBottom: 3,
          maxHeight: "300px",
        }}
      />

      <Typography
        variant="h6"
        gutterBottom
        sx={{
          fontFamily: "Monserat, sans-serif",
          fontWeight: 600,
          fontSize: "1.5rem",
          textTransform: "uppercase",
          textDecoration: "underline",
          textDecorationColor: "red",
          textDecorationThickness: "2px",
        }}
      >
        MARATÓN CALLE ANGOSTA 2025
      </Typography>
      
      <Grid container spacing={4}>
        {/* Información */}
        <Grid item xs={12} md={6}>
          <List sx={{ marginTop: "30px" }}>
            <ListItemIcon sx={{ minWidth: "auto", marginRight: "8px" }}>
              <CalendarMonthIcon style={{ color: "red", fontSize: "30px" }} />
            </ListItemIcon>
            <ListItemText primary="19 de Enero del 2025" />
          </List>
          <List sx={{ marginTop: "10px" }}>
            <ListItemIcon sx={{ minWidth: "auto", marginRight: "8px" }}>
              <LocationOnIcon style={{ color: "red", fontSize: "30px" }} />
            </ListItemIcon>
            <ListItemText primary="Villa Mercedes - San Luis" />
          </List>
          <List sx={{ marginTop: "10px" }}>
            <ListItemIcon sx={{ minWidth: "auto", marginRight: "8px" }}>
              <BusinessIcon style={{ color: "red", fontSize: "30px" }} />
            </ListItemIcon>
            <ListItemText primary="Organiza: Municipalidad de Villa Mercedes" />
          </List>
        </Grid>

        {/* Mapa */}
        <Grid item xs={12} md={6}>
          <Box
            component="iframe"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3333.902364728415!2d-65.45270302407323!3d-33.67637568069412!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95d4357cfb76b267%3A0x4e8bdfdd0d6cb3fd!2sVilla%20Mercedes%2C%20San%20Luis%2C%20Argentina!5e0!3m2!1sen!2sar!4v1677743337746!5m2!1sen!2sar"
            width="100%"
            height="100%"
            style={{ border: 0, marginTop: '30px' }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></Box>
        </Grid>
      </Grid>

      <Typography
              variant="h6"
              gutterBottom
              sx={{
                marginTop: 8,
                fontFamily: "Monserat, sans-serif",
                fontWeight: 600,
                fontSize: "1.5rem",
                textTransform: "uppercase",
                textDecoration: "underline",
                textDecorationColor: "red",
                textDecorationThickness: "2px",
              }}
            >
              DOCUMENTACIÓN IMPORTANTE
            </Typography>
            
            {/* Enlace de vista previa del PDF */}
            <Button
              variant="contained"
              color="primary"
              href={deslinde} // Usa `href` en lugar de `src` para abrir el PDF
              target="_blank" // Abre el PDF en una nueva pestaña
              rel="noopener noreferrer" // Mejora de seguridad al abrir un enlace externo
              sx={{
                marginTop: 2,
                marginRight: 5,
                backgroundColor: "red",
                ":hover": { backgroundColor: "darkred", color: "white" },
              }}
            >
              Deslinde de responsabilidades
            </Button>

            <Box sx={{ backgroundColor: "#f5f5f5", padding: 4, borderRadius: 2, maxWidth: "90%", margin: "auto", marginTop: 4, marginBottom: 4, fontFamily: "Arial, sans-serif", boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)", }}>
            {/* Grid con imágenes clicables */}
            <Grid container spacing={4} sx={{ marginTop: 5, alignContent: "center", justifyContent: "center" }}>
            {[info, R21k, R10k, R5k, R3k, agua].map((image, index) => (
            <Box
              key={index}
              component="img"
              src={image}
              alt={`Imagen ${index + 1}`}
              sx={{
                borderRadius: 2,
                marginBottom: 3,
                maxHeight: "400px",
                padding: 2,
                cursor: "pointer",
                "&:hover": {
                        transform: "scale(1.05)",
                },
              }}
            onClick={() => handleOpenModal(image)} // Abre el modal con la imagen correspondiente
            />
            ))}
            </Grid>

            {/* Modal para mostrar imagen ampliada */}
            <Dialog open={openModal} onClose={handleCloseModal} maxWidth="lg">
              <DialogContent>
                {currentImage && (
                  <Box
                  component="img"
                  src={currentImage}
                  alt="Imagen ampliada"
                  sx={{
                    maxWidth: "100%",
                    maxHeight: "90vh",
                    borderRadius: 2,
                  }}
                  />
                  )}
              </DialogContent>
            </Dialog>
            </Box>
      
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          marginTop: 8,
          fontFamily: "Monserat, sans-serif",
          fontWeight: 600,
          fontSize: "1.5rem",
          textTransform: "uppercase",
          textDecoration: "underline",
          textDecorationColor: "red",
          textDecorationThickness: "2px",
        }}
      >
        Inscripciones
      </Typography>

      {/*Aqui arrancan las card de las distancias*/}
      <Grid item xs={12} sm={12}>
      <Grid item xs={12} sm={12}>
  <Grid container spacing={4}>
    {periods.length > 0 ? (
      <>
        {/* Tarjetas para otros periodos */}
        {periods.some((period) => period.periodName !== "Adaptado") && (
          <>
            {periods
              .filter((period) => period.periodName !== "Adaptado")
              .map((period) => (
                <Grid item xs={12} sm={4} md={3} key={period.id}>
                  <Card
                    variant="outlined"
                    sx={{
                      maxWidth: 300,
                      transition: "0.2s",
                      boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
                      borderRadius: 3,
                      "&:hover": {
                        transform: "scale(1.05)",
                      },
                    }}
                  >
                    <CardContent>
                      <Typography variant="h2" gutterBottom>
                        {period.distance}
                      </Typography>
                      <Typography variant="h7" gutterBottom>
                        {period.periodName}
                      </Typography>
                      <Typography variant="h5">${period.price}</Typography>
                    </CardContent>
                    <Box sx={{ textAlign: "center", paddingBottom: 2 }}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() =>
                          handleInscribirse(
                            "Maraton Calle Angosta 2025",
                            period.distance,
                            period.periodName,
                            period.price,
                            img1
                          )
                        }
                        disabled={isSoldOut}
                      >
                        Inscribirse
                      </Button>
                      {isSoldOut && (
                        <Box
                          sx={{
                            textAlign: "center",
                            backgroundColor: "rgba(255, 0, 0, 0.7)",
                            color: "white",
                            padding: 1,
                          }}
                        >
                          <Typography variant="h6">Cupos agotados</Typography>
                          <Typography variant="h6" sx={{ fontSize: "0.8rem" }}>
                            Proximamente nuevo periodo
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Card>
                </Grid>
              ))}
          </>
        )}

        {/* Tarjetas para "Adaptado: Motriz, Visual, Intelectual o Down" */}
        {periods.some((period) => period.periodName === "Adaptado") && (
          <>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                gutterBottom
                sx={{ marginTop: 4, fontWeight: "bold", textAlign: "left" }}
              >
                Categorias Adaptado: Motriz, Visual, Intelectual o Down
              </Typography>
            </Grid>
            {periods
              .filter((period) => period.periodName === "Adaptado")
              .map((period) => (
                <Grid item xs={12} sm={4} md={3} key={period.id}>
                  <Card
                    variant="outlined"
                    sx={{
                      maxWidth: 300,
                      transition: "0.2s",
                      boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
                      borderRadius: 3,
                      "&:hover": {
                        transform: "scale(1.05)",
                      },
                    }}
                  >
                    <CardContent>
                      <Typography variant="h2" gutterBottom>
                        {period.distance}
                      </Typography>
                      <Typography variant="h7" gutterBottom>
                        {period.periodName}
                      </Typography>
                      <Typography variant="h5">${period.price}</Typography>
                    </CardContent>
                    <Box sx={{ textAlign: "center", paddingBottom: 2 }}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() =>
                          handleInscribirse(
                            "Maraton Calle Angosta 2025",
                            period.distance,
                            period.periodName,
                            period.price,
                            img1
                          )
                        }
                        disabled={isSoldOut}
                      >
                        Inscribirse
                      </Button>
                      {isSoldOut && (
                        <Box
                          sx={{
                            textAlign: "center",
                            backgroundColor: "rgba(255, 0, 0, 0.7)",
                            color: "white",
                            padding: 1,
                          }}
                        >
                          <Typography variant="h6">Cupos agotados</Typography>
                          <Typography variant="h6" sx={{ fontSize: "0.8rem" }}>
                            Proximamente nuevo periodo
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Card>
                </Grid>
              ))}
          </>
        )}
      </>
    ) : (
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          marginTop: 8,
          fontFamily: "Monserat, sans-serif",
          fontWeight: 600,
          fontSize: "1.5rem",
          marginLeft: 5,
        }}
      >
        Próximamente disponibles!
      </Typography>
    )}
  </Grid>
</Grid>;



        </Grid>
    </Box>
  );
};

export default CalleAngosta2025;
