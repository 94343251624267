import React, {useState, useEffect} from "react";
import { Box, Typography, Grid, Card, CardContent, Button, List, ListItemIcon, ListItemText, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../../../config/config";
import img1 from "../ValleDelaLuna2025/banner.jpg";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';
import reglamento from '../ValleDelaLuna2025/Reglamento Valle de la luna 2025.pdf'
import concentimiento from '../ValleDelaLuna2025/CONSENTIMIENTO.pdf'

const ValleDelaLuna2025 = () => {
  const navigate = useNavigate();
  const [periods, setPeriods] = useState([]);
  const isSoldOut = false;

  // Obtener los periodos de inscripción desde el backend
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchPeriods = async () => {
      try {
        const response = await axios.get(`${config.INSCRIPTION_URL}/api/inscription-periods`, {
          params: { raceName: "desafio_valle_de_la_luna_2025" }, // Filtra por nombre de la carrera
        });
        // Filtrar los periodos que son válidos para la fecha actual
        const currentDate = new Date();
        const filteredPeriods = response.data.periods.filter((period) => {
          const startDate = new Date(period.startDate); // Fecha de inicio del periodo
          const endDate = new Date(period.endDate); // Fecha de finalización del periodo
          return currentDate >= startDate && currentDate <= endDate; // Periodos activos
        });

        setPeriods(filteredPeriods);
      } catch (error) {
        console.error("Error al obtener los períodos:", error);
      }
    };

    fetchPeriods();
  }, []);

  const handleInscribirse = (raceName, distance, periodoInscripcion, price, image) => {
    const numericPrice = parseFloat(price);
    const priceTax = numericPrice * 0.0639; // Calcula el 6.39% del precio original
    // Redirige al componente Forms con los datos seleccionados
    navigate(`/${raceName}/forms05`, {
      state: { distance, periodoInscripcion, price: numericPrice, priceTax: priceTax.toFixed(2), image },
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        padding: 4,
        borderRadius: 2,
        maxWidth: "90%",
        margin: "auto",
        marginTop: 4,
        marginBottom: 4,
        fontFamily: "Arial, sans-serif",
        boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
      }}
    >
      {/* Banner Image */}
      <Box
        component="img"
        src={img1}
        alt="Banner"
        sx={{
          width: "100%",
          borderRadius: 2,
          marginBottom: 3,
          maxHeight: "300px",
        }}
      />

<List sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}>
            <CalendarMonthIcon style={{ color: 'red', fontSize: '30px' }} />
        </ListItemIcon>
        <ListItemText primary="1 de Noviembre del 2025" primaryTypographyProps={{ style: { fontSize: '1.2rem', textAlign: 'left' } }} />
      </List>

      <List sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}>
            <LocationOnIcon style={{ color: 'red', fontSize: '30px' }} />
        </ListItemIcon>
        <ListItemText primary="Ischigualasto - San Juan" primaryTypographyProps={{ style: { fontSize: '1.2rem', textAlign: 'left' } }} />
      </List>

      <List sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}>
            <BusinessIcon style={{ color: 'red', fontSize: '30px' }} />
        </ListItemIcon>
        <ListItemText primary="Organiza: Adventure Pro" primaryTypographyProps={{ style: { fontSize: '1.2rem', textAlign: 'left' } }} />
      </List>

      <Typography
        variant="h6"
        gutterBottom
        sx={{
          fontFamily: "Monserat, sans-serif",
          fontWeight: 600,
          fontSize: "1.5rem",
          textTransform: "uppercase",
          textDecoration: "underline",
          textDecorationColor: "red",
          textDecorationThickness: "2px",
        }}
      >
      Información de la Carrera
      </Typography>
      <Typography variant="body1" sx={{textAlign: "center"}}>
        En medio de la imponente belleza natural de Ischigualasto, también conocido como el Valle de la Luna, este evento ofrece a los ciclistas la oportunidad de explorar un terreno espectacular y desafiante. La gran novedad de esta 3ra edición es el cambio de horario: la largada será al atardecer, en un colorido SUNSET que convertirá la experiencia en algo único e inolvidable.
        <br></br>
        El parque provincial, famoso por su riqueza geológica y paisajes surrealistas, se convierte en el escenario perfecto para una competencia de ciclismo inolvidable. Los competidores se enfrentarán a un emocionante recorrido que combina tramos de senderos, caminos rurales y pasajes impresionantes a través de formaciones rocosas únicas. Su relieve no presenta una complejidad significativa, lo que hace que la carrera sea accesible tanto para ciclistas amateurs como para ciclistas de élite que buscan su mejor performance.
        <br></br>
        Vení y descubrí la combinación perfecta entre naturaleza, deporte y adrenalina en el mágico entorno del Parque Provincial Ischigualasto.
        <br></br>
        ¡Prepárate para pedalear y sentir la emoción de una experiencia inolvidable bajo la intensidad y el encanto de la luna llena!
      </Typography>

      <Typography
        variant="h6"
        gutterBottom
        sx={{
          marginTop: 8,
          fontFamily: "Monserat, sans-serif",
          fontWeight: 600,
          fontSize: "1.5rem",
          textTransform: "uppercase",
          textDecoration: "underline",
          textDecorationColor: "red",
          textDecorationThickness: "2px",
        }}
      >
        DOCUMENTACIÓN IMPORTANTE
      </Typography>
      
      {/* Enlace de vista previa del PDF */}
      <Button
        variant="contained"
        color="primary"
        href={reglamento} // Usa `href` en lugar de `src` para abrir el PDF
        target="_blank" // Abre el PDF en una nueva pestaña
        rel="noopener noreferrer" // Mejora de seguridad al abrir un enlace externo
        sx={{
          marginTop: 2,
          marginRight: 5,
          backgroundColor: "red",
          ":hover": { backgroundColor: "darkred", color: "white" },
        }}
      >
        Reglamento
      </Button>

      <Button
        variant="contained"
        color="primary"
        href={concentimiento} // Usa `href` en lugar de `src` para abrir el PDF
        target="_blank" // Abre el PDF en una nueva pestaña
        rel="noopener noreferrer" // Mejora de seguridad al abrir un enlace externo
        sx={{
          marginTop: 2,
          backgroundColor: "red",
          ":hover": { backgroundColor: "darkred", color: "white" },
        }}
      >
        CONSENTIMIENTO
      </Button>

      <Typography
        variant="h6"
        gutterBottom
        sx={{
          fontFamily: "Monserat, sans-serif",
          fontWeight: 600,
          fontSize: "1.5rem",
          textTransform: "uppercase",
          textDecoration: "underline",
          textDecorationColor: "red",
          textDecorationThickness: "2px",
          marginTop: 5,
        }}
      >
        Información de la inscripción
      </Typography>
      <Typography variant="body1" sx={{textAlign: "center"}}>
        Al momento de inscribirte podés optar por la opción de comprar la inscripción con remera o sin remera oficial de la carrera. La remera está disponible solo para los ciclistas que se inscriben a la competencia.
        <br></br>
        ¡PRECIO PROMO LANZAMIENTO!: El 01 de Febrero es la apertura oficial con un precio especial solo para un cupo de 500.
        <br></br>
        Una vez agotado estos cupos, se podrán seguir inscribiendo con el precio normal.
        <br></br>
        Los ciclistas de la Provincia de San Juan, pueden acceder a un DESCUENTO RESIDENTE inscribiéndose de manera presencial con la presentación del DNI en RUSTIK AVENTURA (Hipólito Yrigoyen Sur 41) y los residentes en Valle fértil también, enviando un mail a desafiovalledelaluna@adventurepro.com.ar
        <Typography sx={{textDecoration: 'underline', fontWeight: 'bold', marginTop: 2}}>Contactos Oficiales</Typography>
        Whatsapp: <Link href= 'https://wa.me/26444573797' target='_blank' rel='noopener'>26444573797</Link><br></br>Mail: <Link href= 'mailto:desafiovalledelaluna@adventurepro.com.ar'>desafiovalledelaluna@adventurepro.com.ar</Link><br></br>Web: <Link href= 'https://www.desafiovalledelaluna.com.ar/' target='_blank' rel='noopener'>www.desafiovalledelaluna.com.ar</Link>
      </Typography>
      
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          marginTop: 8,
          fontFamily: "Monserat, sans-serif",
          fontWeight: 600,
          fontSize: "1.5rem",
          textTransform: "uppercase",
          textDecoration: "underline",
          textDecorationColor: "red",
          textDecorationThickness: "2px",
        }}
      >
        Inscripciones
      </Typography>

      {/*Aqui arrancan las card de las distancias*/}
      <Grid item xs={12} sm={12}>
          <Grid container spacing={4}>
            {periods.length > 0 ? (
              periods.map((period) => (
                <Grid item xs={12} sm={4} md={3} key={period.id}>
                  <Card
                    variant="outlined"
                    sx={{
                      maxWidth: 300,
                      transition: "0.2s",
                      boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
                      borderRadius: 3,
                      "&:hover": {
                        transform: "scale(1.05)",
                      },
                    }}
                  >
                    <CardContent>
                      <Typography variant="h2" gutterBottom>
                        {period.distance}
                      </Typography>
                      <Typography variant="h7" gutterBottom>
                        {period.periodName}
                      </Typography>
                      <Typography variant="h5">${period.price}</Typography>
                    </CardContent>
                    <Box sx={{ textAlign: "center", paddingBottom: 2 }}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() =>
                          handleInscribirse(
                            "Desafio Valle de la Luna 2025",
                            period.distance,
                            period.periodName,
                            period.price,
                            img1
                          )
                        }
                        disabled={isSoldOut} // Desactiva el botón si los cupos están agotados
                      >
                        Inscribirse
                      </Button>
                      {isSoldOut && (
                        <Box
                          sx={{
                            textAlign: "center",
                            backgroundColor: "rgba(255, 0, 0, 0.7)",
                            color: "white",
                            padding: 1,
                          }}
                        >
                          <Typography variant="h6">Cupos agotados</Typography>
                          <Typography
                            variant="h6"
                            sx={{ fontSize: "0.8rem" }}
                          >
                            Proximamente nuevo periodo
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Card>
                </Grid>
              ))
            ) : (
              <Typography variant="h6" gutterBottom sx={{marginTop: 8, fontFamily: "Monserat, sans-serif", fontWeight: 600, fontSize: "1.5rem"}}>Próximamente disponibles!</Typography>
            )}
          </Grid>
        </Grid>
    </Box>
  );
};

export default ValleDelaLuna2025;
