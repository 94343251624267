import React, { useState, useEffect } from 'react';
import { TextField, Box, Typography, Grid, Select, MenuItem, InputLabel, FormControl, Button } from '@mui/material';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react';
import { v4 as uuidv4 } from 'uuid';
import config from '../../../../config/config.js'

const Forms = () => {
  const { raceName } = useParams();
  const { state } = useLocation();
  const { distance, price, periodoInscripcion, priceTax, image } = state || {};
  const formattedRaceName = raceName.toLowerCase().replace(/\s+/g, '_');
  const [gender, setGender] = useState('');
  const [discountCode, setDiscountCode] = useState('');
  const [discountedPrice, setDiscountedPrice] = useState(null);
  const [preferenceId] = useState(null);
  const [email, setEmail] = useState('');
  const [shirtSize, setShirtSize] = useState('');
  const [Procedencia, setProcedencia] = useState('');
  const [message, setMessage] = useState('');
  const [categoria, setCategoria] = useState('');
  
  // Inicializa Mercado Pago solo cuando el componente se monta
  useEffect(() => {
    initMercadoPago('APP_USR-72879720-3f27-4cb1-b4fd-38f4ba5edaea', { locale: 'es-AR' });
  }, []); // El array vacío asegura que se ejecuta solo una vez

  const createPreference = async (formId) => {
    try {
      const priceToPay = calculatePrice();
      const payerEmail = email; // Usa el email capturado del estado
      const applicationFee = price * 0.03;
      const sellerAmount = priceToPay - applicationFee;
    
      /*const response = await axios.post('http://localhost:5000/create_preference', { */
      const response = await axios.post(`${config.INSCRIPTION_URL}/create_preference`, {
        title: `Inscripción - ${distance} - ${periodoInscripcion} - ${raceName}`,
        quantity: 1,
        price: priceToPay,
        formId,
        payerEmail,      // Añadir el email del pagador
        applicationFee,  // Añadir la comisión del marketplace
        sellerAmount,
        raceName: formattedRaceName,
      });
      /*console.log('Respuesta de Mercado Pago:', response.data);*/
      const { initPoint } = response.data;
    return initPoint;
    } catch (error) {
      console.error('Error al crear la preferencia:', error);
      alert("Hubo un error al procesar el pago. Intenta nuevamente.");
      return null;
    }
  };

  const handleDiscountCodeChange = (event) => {
    setDiscountCode(event.target.value);
  };
/** */
const applyDiscount = async () => {
  if (!discountCode) {
    alert("Por favor, ingresa un código de descuento.");
    return;
  }

  try {
    // Llamada al backend para validar el código
    const response = await axios.get(`${config.INSCRIPTION_URL}/api/discounts`, {
      params: { raceName: formattedRaceName, code: discountCode },
    });

    const { discountType, discountValue, expirationDate, usageCount, cantidad } = response.data;

    // Validar que el código no haya expirado
    const today = new Date();
    if (new Date(expirationDate) < today) {
      alert("El código de descuento ha expirado.");
      return;
    }

    // Validar si el código alcanzó su límite de usos
    if (usageCount >= cantidad) {
      alert("El código de descuento ya no está disponible.");
      return;
    }

    // Calcular el precio con descuento
    let newPrice = price; // Precio inicial

    if (discountType === "percentage") {
      const discountFactor = 1 - discountValue / 100; // Convierte el porcentaje en un factor de descuento
      newPrice = price * discountFactor;
    } else if (discountType === "amount") {
      newPrice = price - discountValue; // Resta el monto fijo
    }
/** */
     // Asegurarse de que el precio no sea negativo (o inválido)
     newPrice = Math.max(newPrice, 0); // Si es menor a 0, lo fuerza a 0

     // Verifica y actualiza el estado
     if (newPrice === 0) {
       console.log("El precio con descuento es 0.");
     }

    setDiscountedPrice(newPrice);
    try {
      await axios.post(`${config.INSCRIPTION_URL}/api/discounts/use`, {
        raceName: formattedRaceName,
        code: discountCode,
      });
      setMessage('Codigo de descuento aplicado');
      console.log("Uso del código registrado con éxito.");
    } catch (error) {
      console.error("Error al registrar el uso del código de descuento:", error);
    }
  } catch (error) {
    console.error("Error al validar el código de descuento:", error);
    if (error.response && error.response.status === 404) {
      alert("El código de descuento no es válido.");
    } else {
      alert("El código de descuento ya no está disponible.");
    }
  }
};

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleShirtSizeChange = (event) => {
    setShirtSize(event.target.value); // Maneja el cambio de tallaje
    console.log("Talle seleccionado:", event.target.value);
  };

  const handleCategoriaChange = (event) => {
    setCategoria(event.target.value);
    console.log("Categoria:", event.target.value);
  };

  const handleFormSubmit = async () => {
    const formId = uuidv4();
    const finalPrice = calculatePrice(); // Obtén el precio calculado
    const formattedDate = getFormattedDate();
    const formData = {
      formId,
      FirstName: document.getElementById('first_name').value,
      LastName: document.getElementById('last_name').value,
      DNI: document.getElementById('document_number').value,
      gender,
      DateOfBirth: formattedDate,
      age: document.getElementById('age').value,
      categoria,
      email: document.getElementById('email').value,
      phone: document.getElementById('phone').value,
      Nacion: document.getElementById('Nation').value,
      Procedencia,
      talle: shirtSize,
      running_team: document.getElementById('Team').value,
      distance,
      price,
      discount_code: discountCode,
      discounted_price: discountedPrice || price,
      priceTax,
      paymentOption: '100%',
      payment_price: finalPrice,
      payment_status: finalPrice === 0 ? 'free' : null, // Lógica actualizada
      pending_price: calculatePendingPrice(),
      periodoInscripcion,
      raceName: formattedRaceName,
      contacto_emergencia: document.getElementById('contacto_emergencia').value,
      cel_emergencia: document.getElementById('cel_emergencia').value
    };
    setEmail(formData.email); // Establece el correo electrónico para la preferencia
    try {
      /**await axios.post('http://localhost:5000/api/submitform', formData); */
      await axios.post(`${config.INSCRIPTION_URL}/submitform`, formData);
      console.log('Formulario enviado correctamente');
      return formId;
    } catch (error) {
      console.error('Error al enviar el formulario:', error);
      return null;
    }
  };
/** */
const handleButtonClick = async () => {
  // Primero envía los datos del formulario
  const formId = await handleFormSubmit();

  if (formId) {
    // Verifica si el precio final es 0
    if (calculatePrice() === 0) {
      alert('Inscripción realizada con éxito');
      window.location.href = 'https://esfuerzodeportivosr.com.ar/'; // Redirige al home
      return; // Detiene la ejecución de la función
    }

    // Si el precio es mayor que 0, crea la preferencia de pago
    const initPoint = await createPreference(formId);
    if (initPoint) {
      window.location.href = initPoint; // Redirige a la URL de pago de Mercado Pago
    }
  }
};

  const calculatePrice = () => {
    const basePrice = discountedPrice !== undefined && discountedPrice !== null ? discountedPrice : price;
    return basePrice;
  };

  const calculatePendingPrice = () => {
    return null; // Siempre null
  };
  
  const handleProcedenciaChange = (event) => {
    setProcedencia(event.target.value);
  };

  const [birthDate, setBirthDate] = useState({
    day: '',
    month: '',
    year: '',
  });
  
  const handleDateChange = (e, type) => {
    const value = e.target.value;
    setBirthDate((prev) => ({ ...prev, [type]: value }));
  };
  
  // Cuando envíes el formulario, convierte los valores a un formato válido (e.g., "YYYY-MM-DD"):
  const getFormattedDate = () => {
    const { day, month, year } = birthDate;
    if (day && month && year) {
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    }
    return '';
  };  
  

  return (
    <Box
      sx={{
        backgroundColor: '#f5f5f5',
        padding: { xs: 2, md: 4},
        maxWidth: '100%',
        margin: 'auto',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      
      <Box
      sx={{
        backgroundColor: '#f5f5f5',
        width: '100%',
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' }, // Columna en pantallas pequeñas, fila en pantallas grandes
        justifyContent: 'center',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      <Box
      sx={{
        backgroundColor: '#f5f5f5',
        padding: { xs: 2, md: 4},
        borderRadius: 2,
        maxWidth: { xs: '100%', md: '80%' },
        fontFamily: 'Arial, sans-serif',
        boxShadow: "0px 1px 10px black",
        marginBottom: { xs: 2, md: 0 },
      }}
    >
      <Box
        component="img"
        src={image}
        alt="Banner"
        sx={{
          width: '100%',
          borderRadius: 2,
          marginBottom: 3,
          maxHeight: '300px',
          boxShadow: "0px 1px 10px black",
        }}
      />
      <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Monserat, sans-serif', fontWeight: 600, fontSize: '1.5rem', textTransform: 'uppercase', textDecoration: 'underline', textDecorationColor: 'red', textDecorationThickness: '2px'}}>
        Datos del corredor
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="first_name"
            label="Nombre"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="last_name"
            label="Apellido"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="document_number"
            label="Número de Documento"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required variant="outlined">
            <InputLabel id="gender-label">Género</InputLabel>
            <Select
              id="gender"
              labelId="gender-label"
              value={gender}
              onChange={handleGenderChange}
              label="Género"
              required
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#ccc',
                  },
                  '&:hover fieldset': {
                    borderColor: '#666',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#1976d2',
                  },
                },
              }}
            >
              <MenuItem value="masculino">Masculino</MenuItem>
              <MenuItem value="femenino">Femenino</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
        <Box display="flex" alignItems="center" gap={2}>
          <Typography>Fecha de Nacimiento</Typography> 
          <TextField
            required
            id="birth_day"
            label="DD"
            variant="outlined"
            type="number"
            value={birthDate.day || ""}
            error={birthDate.day && (birthDate.day < 1 || birthDate.day > 31)}
            helperText={
             birthDate.day && (birthDate.day < 1 || birthDate.day > 31)
              ? "Entre 1 y 31"
              : ""
            }
            onChange={(e) => handleDateChange(e, 'day')}
      sx={{
        width: '80px',
        '& .MuiInputLabel-root': { color: '#333' },
        '& .MuiOutlinedInput-root': {
          '& fieldset': { borderColor: '#ccc' },
          '&:hover fieldset': { borderColor: '#666' },
          '&.Mui-focused fieldset': { borderColor: '#1976d2' },
        },
      }}
    />
    <TextField
      required
      id="birth_month"
      label="MM"
      variant="outlined"
      type="number"
      value={birthDate.month || ""}
      error={birthDate.month && (birthDate.month < 1 || birthDate.month > 12)}
      helperText={
        birthDate.month && (birthDate.month < 1 || birthDate.month > 12)
          ? "Entre 1 y 12"
          : ""
      }
      onChange={(e) => handleDateChange(e, 'month')}
      sx={{
        width: '80px',
        '& .MuiInputLabel-root': { color: '#333' },
        '& .MuiOutlinedInput-root': {
          '& fieldset': { borderColor: '#ccc' },
          '&:hover fieldset': { borderColor: '#666' },
          '&.Mui-focused fieldset': { borderColor: '#1976d2' },
        },
      }}
    />
    <TextField
      required
      id="birth_year"
      label="AAAA"
      variant="outlined"
      type="number"
      value={birthDate.year || ""}
      error={
        birthDate.year &&
        (birthDate.year < 1900 || birthDate.year > new Date().getFullYear())
      }
      helperText={
        birthDate.year &&
        (birthDate.year < 1900 || birthDate.year > new Date().getFullYear())
          ? `Entre 1900 y ${new Date().getFullYear()}`
          : ""
      }
      onChange={(e) => handleDateChange(e, 'year')}
      sx={{
        width: '100px',
        '& .MuiInputLabel-root': { color: '#333' },
        '& .MuiOutlinedInput-root': {
          '& fieldset': { borderColor: '#ccc' },
          '&:hover fieldset': { borderColor: '#666' },
          '&.Mui-focused fieldset': { borderColor: '#1976d2' },
        },
      }}
    />
    </Box>
          </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="age"
            label="Edad al día de la carrera"
            variant="outlined"
            type="number"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
              <FormControl fullWidth required variant="outlined">
                <InputLabel id="categoria-label">Categoria</InputLabel>
                <Select
                  id='categoria'
                  name="categoria"
                  value={categoria}
                  onChange={handleCategoriaChange}
                  label="Categoria"
                  required
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300, // Altura máxima de la lista desplegable
                        overflow: 'auto', // Habilita el desplazamiento
                      },
                    },
                  }}
                  sx={{
                    '& .MuiInputLabel-root': { color: '#333' },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#ccc',
                      },
                      '&:hover fieldset': {
                        borderColor: '#666',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#1976d2',
                      },
                    },
                  }}
                >
                  <MenuItem value="POR EDADES">POR EDADES</MenuItem>
                  <MenuItem value="Adaptado - Motriz">Adaptado - Motriz</MenuItem>
                  <MenuItem value="Adaptado - Visual">Adaptado - Visual</MenuItem>
                  <MenuItem value="Adaptado - Intelectual">Adaptado - Intelectual</MenuItem>
                  <MenuItem value="Adaptado - Down">Adaptado - Down</MenuItem>
                </Select>
              </FormControl>
            </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="email"
            label="Correo Electrónico"
            variant="outlined"
            type="email"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="phone"
            label="Teléfono"
            variant="outlined"
            type="tel"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="Nation"
            label="Nacionalidad"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required variant="outlined">
            <InputLabel id="province-label">Provincia</InputLabel>
            <Select
              id="province"
              labelId="province-label"
              value={Procedencia}
              onChange={handleProcedenciaChange}
              label="Provincia"
              required
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300, // Altura máxima de la lista desplegable
                    overflow: 'auto', // Habilita el desplazamiento
                  },
                },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#ccc',
                  },
                  '&:hover fieldset': {
                    borderColor: '#666',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#1976d2',
                  },
                },
              }}
            >
              {[
                'Buenos Aires', 'CABA', 'Catamarca', 'Chaco', 'Chubut',
                'Córdoba', 'Corrientes', 'Entre Ríos', 'Formosa', 'Jujuy',
                'La Pampa', 'La Rioja', 'Mendoza', 'Misiones', 'Neuquén',
                'Río Negro', 'Salta', 'San Juan', 'San Luis', 'Santa Cruz',
                'Santa Fe', 'Santiago del Estero', 'Tierra del Fuego', 'Tucumán', 'Otra'
              ].map((province) => (
                <MenuItem key={province} value={province}>
                  {province}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
              <FormControl fullWidth required variant="outlined">
                <InputLabel id="shirt-size-label">Talle de remera</InputLabel>
                <Select
                  id='talle'
                  name="talle"
                  value={shirtSize}
                  onChange={handleShirtSizeChange}
                  label="Talle de remera"
                  required
                  sx={{
                    '& .MuiInputLabel-root': { color: '#333' },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#ccc',
                      },
                      '&:hover fieldset': {
                        borderColor: '#666',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#1976d2',
                      },
                    },
                  }}
                >
                  <MenuItem value="XS">XS</MenuItem>
                  <MenuItem value="S">S</MenuItem>
                  <MenuItem value="M">M</MenuItem>
                  <MenuItem value="L">L</MenuItem>
                  <MenuItem value="XL">XL</MenuItem>
                  <MenuItem value="XXL">XXL</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="Team"
            label="Running Team"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="contacto_emergencia"
            label="Nombre contacto de emergencia"
            variant="outlined"
            required
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="cel_emergencia"
            label="Telefono de emergencia"
            variant="outlined"
            required
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
      </Grid>
      
      <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginTop: 8 }}>
            Código de Descuento
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={8}>
              <TextField
                fullWidth
                id="discountCode"
                label="Código"
                variant="outlined"
                value={discountCode}
                onChange={handleDiscountCodeChange}
                sx={{
                  '& .MuiInputLabel-root': { color: '#333' },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#ccc',
                    },
                    '&:hover fieldset': {
                      borderColor: '#666',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#1976d2',
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={4}>
            <Button
              fullWidth
              variant="contained"
              onClick={applyDiscount}
              sx={{
                height: '100%',
                backgroundColor: 'red', // Cambia el color de fondo a rojo
                color: 'white', // Cambia el color del texto a blanco
                '&:hover': {
                  backgroundColor: 'darkred', // Cambia el color al pasar el mouse
                },
              }}
            >
              Aplicar
            </Button>
            </Grid>
          </Grid>
          {message}

              <Typography variant="h6" gutterBottom sx={{marginTop: 4, fontFamily: 'Monserat, sans-serif', fontWeight: 600,     fontSize: '1.3rem', textTransform: 'uppercase', textDecoration: 'underline', textDecorationColor: 'red', textDecorationThickness: '2px'}}>
               Información de la inscripción en {raceName}
              </Typography>
              <Typography variant="h6" gutterBottom>
                {distance} - {periodoInscripcion}
              </Typography>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginTop: 2 }}>
                Precio: ${(price).toFixed(2)}
              </Typography>
              
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginTop: 2 }}>
          Precio con descuento: ${calculatePrice().toFixed(2)}
          </Typography>
          <Button
            fullWidth
            variant="contained"
            onClick={handleButtonClick}
            sx={{
              marginTop: 3,
              backgroundColor: 'red',
              color: 'white',
              '&:hover': {
                backgroundColor: 'darkred',
              },
            }}
          >
            Finalizar
          </Button> 
          {preferenceId && (
            <Wallet
              initialization={{ preferenceId: preferenceId }}
              customization={{ texts: { valueProp: 'smart_option' } }}
            />
          )}
        </Box> 
      </Box>
    </Box>
  );
};

export default Forms;
