import React from 'react';
import { List, ListItemText, ListItemIcon, Box, ListItemButton } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import LogoutIcon from '@mui/icons-material/Logout';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';

export default function Sidebar({ onComponentChange, closeSidebar }) {
  const navigate = useNavigate();
  const auth = getAuth();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/loginuser');
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
    }
  };

  return (
    <Box
      sx={{
        width: 280,
        bgcolor: '#f5f5f5',
        color: 'red',
        boxShadow: "0px 1px 10px black",
        minHeight: '700px'
      }}
    >
      <List sx={{ marginTop: '70px', display: 'flex', flexDirection: 'column' }}>
        <ListItemButton onClick={() => { onComponentChange('MainContent'); closeSidebar(); }}>
          <ListItemIcon>
            <DashboardIcon style={{ color: 'red' }} />
          </ListItemIcon>
          <ListItemText primary="Inicio" />
        </ListItemButton>
        <ListItemButton onClick={() => { onComponentChange('InscriptosList'); closeSidebar(); }}>
          <ListItemIcon>
            <DirectionsRunIcon style={{ color: 'red' }} />
          </ListItemIcon>
          <ListItemText primary="Inscriptos" />
        </ListItemButton>
        <ListItemButton onClick={() => { onComponentChange('InscriptionPeriod'); closeSidebar(); }}>
          <ListItemIcon>
            <DateRangeIcon style={{ color: 'red' }} />
          </ListItemIcon>
          <ListItemText primary="Periodos de inscripción" />
        </ListItemButton>
        <ListItemButton onClick={() => { onComponentChange('DiscountCode'); closeSidebar(); }}>
          <ListItemIcon>
            <LocalActivityIcon style={{ color: 'red' }} />
          </ListItemIcon>
          <ListItemText primary="Codigos de descuento" />
        </ListItemButton>
        <ListItemButton onClick={() => { onComponentChange('DownloadExcel'); closeSidebar(); }}>
          <ListItemIcon>
            <CloudDownloadIcon style={{ color: 'red' }} />
          </ListItemIcon>
          <ListItemText primary="Descargar" />
        </ListItemButton>
        <ListItemButton onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon style={{ color: 'red' }} />
          </ListItemIcon>
          <ListItemText primary="Cerrar sesión" />
        </ListItemButton>
      </List>
    </Box>
  );
}